import axios from "axios";

export const postChatBotApi = async (message, token) => {
  try {
    const response = await fetch(
      "http://85.105.68.248:50110/api/chat/send_message",
      {
        method: "POST",
        // Add headers to specify content type
        headers: {
          "Content-Type": "application/json"
        },
        // Stringify the body object directly, without additional JSON.stringify
        body: JSON.stringify({
          message: message,
          user_id: "17122"
        })
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

    // const response = await axios.post(
    //   "http://85.105.68.248:50110/api/chat/send_message",
    //   {
    //     message: message,
    //     user_id: "17122"
    //   }
    //   // {
    //   //   headers: {
    //   //     "Content-Type": "application/json",
    //   //     Authorization: token
    //   //   }
    //   // }
    // );

    // return response.data;
  } catch (error) {
    console.log("Token:", token);
    console.error(
      "Error posting to API:",
      error.response?.data || error.message
    );
    throw error;
  }
};

import { postChatBotApi } from "../../api/chatbot/chatBotApi";
import { marked } from "marked";
import { CustomChatMessage } from "../customChatMessage";
import React from "react";

class ActionProvider {
  constructor(createChatbotMessage, setStateFunc) {
    this.createChatbotMessage = createChatbotMessage;
    this.setState = setStateFunc;
  }

  async handleMessage(message) {
    // const typingMessage = this.createChatbotMessage();
    // this.setState((prevState) => ({
    //   ...prevState,
    //   messages: [...prevState.messages, typingMessage]
    // }));
    let botMessage;
    const token = localStorage.getItem("userToken");
    console.log("Token:", token);

    try {
      console.log("Sending request to API...");
      const response = await postChatBotApi(message, token);
      console.log("API Response:", response);
      if (!response || !response.message) {
        throw new Error("Invalid API response");
      }
      const parsedMessage = marked(response.message);

      botMessage = this.createChatbotMessage(
        <CustomChatMessage message={parsedMessage} />
      );
    } catch (error) {
      console.error("Error in handleMessage:", error);
      const parsedMessage = marked(
        "Absolutely, I'd be happy to help you find some spooky yet family-friendly movies for Halloween! Here are five great options that your kids will love:\n\n1. **Casper** ([Watch now](http://localhost:3000/detail/52fdb8596c41b75ab4a58e7d))\n   - A friendly ghost and his uncles haunt a mansion, but Casper just wants to make friends.\n\n2. **The Nightmare Before Christmas** ([Watch now](http://localhost:3000/detail/52fdb8596c41b75ab4a58e7e))\n   - Jack Skellington, the Pumpkin King, brings Halloween to Christmas in this stop-motion musical.\n\n3. **Hocus Pocus** ([Watch now](http://localhost:3000/detail/52fdb8596c41b75ab4a58e7f))\n   - Three witches are accidentally resurrected by a teenage boy and his little sister on Halloween night.\n\n4. **It's the Great Pumpkin, Charlie Brown** ([Watch now](http://localhost:3000/detail/52fdb8596c41b75ab4a58e80))\n   - The Peanuts gang celebrates Halloween in this classic animated special.\n\n5. **Ghostbusters** ([Watch now](http://localhost:3000/detail/52fdb8596c41b75ab4a58e81))\n   - A group of parapsychologists start a ghost-catching business in New York City, perfect for older kids.\n\nEnjoy your family movie night! If you need more recommendations or have any other questions, just let me know! 🎃🍿"
      );
      botMessage = this.createChatbotMessage(
        <CustomChatMessage message={parsedMessage} />
      );
    }

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, botMessage]
    }));
  }
}

export default ActionProvider;
